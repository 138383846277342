import "../CSS/footer.css";
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';
import { loadCSS } from 'fg-loadcss';
import Grid from '@mui/material/Grid';

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

export default function Footer ({pageWidth}) {
  const styles = {
    text: { 
      fontSize:8+(pageWidth/140)
    },
    icon: { 
      fontSize:15+(pageWidth/140)
    },
  };
    React.useEffect(() => {
        const node = loadCSS(
          'https://use.fontawesome.com/releases/v5.14.0/css/all.css',
          document.querySelector('#font-awesome-css') || document.head.firstChild,
        );
    
        return () => {
        };
      }, []);

    return (
        <Box sx={{ bgcolor: 'background.paper' }} component="footer">
          <div className="sctotop">
            <Icon onClick={handleScrollToTop} baseClassName="fas" className="fa-chevron-up upper" style={styles.text}/>
          </div>
          <Grid container rowSpacing={1} sx={{ p: 6 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
  <Grid item xs={8}>
  <Typography
        variant="subtitle1"
        align="center"
        color="text.secondary"
        component="p"
        style={styles.text}
        sx={{pt:1}}
        >
          <div className="othertext removeText">
        Feel free to contact me
        </div>
                <Box
            sx={{
                '& > :not(style)': {
                m: 2,
                },
            }}
            >
        <div className="AllContacts">
            <a style={{textDecoration: 'none'}} href="https://goo.gl/maps/e6D31xYLbf6J1CZFA">
              <div className="IndividualContacts">
                  <Icon baseClassName="fas" className="fa-location-arrow" style={styles.icon}/>
                  <p className="textchanger" style={styles.text}>Dublin / West Cork</p>
              </div>
              </a>
            <div className="IndividualContacts">
              <Icon baseClassName="fas" className="fa-phone" style={styles.icon}/>
              <p className="textchanger" style={styles.text}>+353874110173</p>
            </div>
          <a style={{textDecoration: 'none'}} href="mailto:karenmcsweeney@karenmcsweeneyart.com">
            <div className="IndividualContacts">
              <Icon baseClassName="fas" className="fa-envelope" style={styles.icon}/>
              <p className="textchanger" style={styles.text}>@karenmcsweeneyart.com</p>
            </div>
            </a>
        </div>
        </Box>
        </Typography>
          </Grid>
          <Grid item xs={4}>
          <div className="othertext removeText">
          <Typography
                variant="subtitle1"
                align="center"
                color="text.secondary"
                component="p"
                style={styles.text}
                >
                Follow me on my socials 
                </Typography>
                </div>
                        <Box
                    sx={{
                        '& > :not(style)': {
                        m: 3,
                        },
                    }}
                    >
                      
              <div className="AllSocials">
                <a href="https://www.facebook.com/profile.php?id=100094472389707">
                  <div className="IndividualSocials">
                    <Icon baseClassName="fab" className="fa-facebook-square" width="100%" style={styles.icon}/>
                  </div>
                </a>
                <a href="https://www.instagram.com/karenmcsweeneyart">
                  <div className="IndividualSocials">
                      <Icon baseClassName="fab" className="fa-instagram-square" style={styles.icon}/>
                  </div>
                </a>
                <div className="IndividualSocials">
                  <Icon baseClassName="fab" className="fa-twitter-square" style={styles.icon}/>
                </div>
              </div>
              </Box>
          </Grid>
        </Grid>
        <br></br>
        </Box>
    );
  }