import '../CSS/TheLittleBlueBoat.css';
import Container from '@mui/material/Container';
import * as React from 'react';
import { loadCSS } from 'fg-loadcss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';

const defaultTheme = createTheme();

export default function TheLittleBlueBoatPage() {
  React.useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.14.0/css/all.css',
      document.querySelector('#font-awesome-css') || document.head.firstChild,
    );

    return () => {
    };
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <div className='contain'>
        <div className='whitebg'>
        <div className='padbot'>
          <Container className="" maxWidth="md">
            <Typography
              sx={{ p: 6 }}
              component="h1"
              variant="h2"
              align="center"
              color="common.secondary"
              fontFamily="'BodoniFLF', sans-serif"
              letterSpacing={7}
              gutterBottom
            >
              <div className="a">
              Under Contruction
              </div>
            </Typography>
            <Icon baseClassName="fas" className="fa-hammer"/>
          </Container>
        </div>
        </div>
        </div>
    </ThemeProvider>
  );
}
