import * as React from 'react';
import "../CSS/GoogleMap.css";
export default function GoogleMap ({pageWidth}) {
    return (
    <div className="GoogleMap">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9930.74845087191!2d-9.32750611998756!3d51.5189560512596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4845a48c79bcbdcd%3A0x8ee1a583e60181ea!2sHighfield%2C%20Co.%20Cork%2C%20P81%20XD66!5e0!3m2!1sen!2sie!4v1689951853318!5m2!1sen!2sie" 
        width={pageWidth/2} height={pageWidth/2} loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="maps"></iframe>
    </div>
    );
  
}