import "../CSS/wave.css";
import logoOver500 from "../images/500x500/logoOver500.png";
export default function BottomWave () {
    return (
      <div className="all">
        <img src={logoOver500} alt="logo" className="logowave"></img>
        <div className="bottom-wave">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffffff" fill-opacity="0.2" 
        d="M0,128L48,133.3C96,139,192,149,288,160C384,171,480,181,576,160C672,139,768,85,864,74.7C960,64,1056,96,1152,106.7C1248,117,1344,107,1392,101.3L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
          </path></svg>
        </div>
      </div>
    );
  }
  