import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import "./CSS/index.css";
import App from './App';

const Root = () => {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);

  const handleResize = () => {
    const newPageWidth = window.innerWidth;
    setPageWidth(newPageWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.StrictMode>
      <App pageWidth={pageWidth} />
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);
