import "../CSS/general.css";
import AboutDesign from '../Functionality/AboutDesign';
import Navbar from '../Functionality/Navbar';
import Footer from '../Functionality/footer';
import BottomWave from '../Functionality/BottomWave';

export default function About ({ pageWidth }) {
    return (
        <div className="flex">
          <Navbar />
          <AboutDesign pageWidth={pageWidth}/>
          <BottomWave pageWidth={pageWidth}/>
          <Footer pageWidth={pageWidth}/>
        </div>
    );
  }