import '../CSS/Gallery.css';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

const defaultTheme = createTheme();

export default function PageNotFound({pageWidth}) {
  const styles = {
    titletitle: { 
      fontSize:30+(pageWidth/50)
    },
    title: { 
      fontSize:20+(pageWidth/70)
    },
    text: { 
      fontSize:8+(pageWidth/140)
    },
    btntext: { 
      fontSize:6+(pageWidth/170)
    },
    locationBox: { 
      width: pageWidth/100
    },
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <div className='contain'>
        <div className='whitebg'>
        <div className='padbot'>
          <Container className="" maxWidth="md">
            <Typography
              sx={{ p: 8 }}
              component="h1"
              variant="h2"
              align="center"
              color="common.secondary"
              fontFamily="'BodoniFLF', sans-serif"
              letterSpacing={7}
              gutterBottom
              style={styles.titletitle}
            >
              <div className="a">
              Page Not Found
              </div>
            </Typography>
          </Container>
        </div>
        </div>
        </div>
    </ThemeProvider>
  );
}
