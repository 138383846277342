import "../CSS/general.css";
import ShopDesign from '../Functionality/ShopDesign';
import Navbar from '../Functionality/Navbar';
import Footer from '../Functionality/footer';
import BottomWave from '../Functionality/BottomWave';

export default function Shop ({ pageWidth }) {
    return (
        <div className="flex">
          <Navbar />
          <ShopDesign pageWidth={pageWidth}/>
          <BottomWave pageWidth={pageWidth}/>
          <Footer pageWidth={pageWidth}/>
        </div>
    );
  }