import "../CSS/navbar.css";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { NavLink } from "react-router-dom";

export default function ButtonAppBar() {

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar className="FullNav" position="fixed" style={{backgroundColor: "#597fba"}}>
        <Toolbar>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <NavLink  to="/" activeClassName="active" className="test">
                <h1 className="NavBrand, rocksalt"> KMCS </h1>
                </NavLink >
            </Grid>
            <Grid item xs={8}>
            {/*<NavLink  to="/thelittleblueboat" activeClassName="active">
              <Button key={5} className="IndividualItem" sx={{ color: '#fff' }}> The Little Blue Boat </Button>
            </NavLink >*/}
            <NavLink  to="/gallery" activeClassName="active" className="test">
              <Button key={4} className="IndividualItem" sx={{ color: '#fff' }}> Gallery </Button>
            </NavLink >
            <NavLink  to="/shop" activeClassName="active" className="test">
              <Button key={3} className="IndividualItem" sx={{ color: '#fff' }}> Shop </Button>
            </NavLink >  
            <NavLink to="/about" activeClassName="active" className="test">
              <Button key={2} className="IndividualItem" sx={{ color: '#fff'}}> About </Button>
            </NavLink >
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
