import "../CSS/Shop.css";
import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Atropos from 'atropos/react';
import Pollocks from "../images/500x500/a_load_of_pollocks.png";
import BoatsRed from "../images/500x500/boats_red_painting.png";
import BoatsBlue from "../images/500x500/BoatsBlue.png";
import BoatsMulti from "../images/500x500/colourful_boats_painting.png";
import MotherMM from "../images/500x500/mother_maiden_matriarch.png";
import Sailor from "../images/500x500/sailor_painting.png";
import Seaside from "../images/500x500/seaside_paintings.png";
import ThreeBoats from "../images/500x500/three_boat_paintings.png";
import WhiteBoat from "../images/500x500/white_boats_painting.png";
import Thalassophile from "../images/500x500/Thalassophile.png";
import SailingUntiesTheKnotsInMyHead from "../images/500x500/SailingUntiesTheKnotsInMyHead.png";
import PiratesTreasure from "../images/500x500/PiratesTreasure.png";
import TheJourneyNotTheArrivalMatters from "../images/500x500/TheJourneyNotTheArrivalMatters.png";
import PiratesTreasure2 from "../images/500x500/PiratesTreasure2.png";
import LostTreasure from "../images/500x500/LostTreasure.png";
import PeaceOfHeaven from "../images/500x500/PeaceOfHeaven.png";
import BlueSeaDay from "../images/500x500/BlueSeaDay.png";
import SOLD from "../images/SVG/SOLD.svg";
import ForSale from "../images/SVG/FOR SALE.svg";
import "atropos/atropos.css";
import { useState } from 'react';
import Modal from 'react-modal';


const ShopDesign = ({pageWidth}) => {

const [isModalOpen, setIsModalOpen] = useState(false);
const [modalImageSrc, setmodalImageSrc] = useState(null);

const handleModalOpen = (imgSrc) => {
  setIsModalOpen(true);
  setmodalImageSrc(imgSrc);
};

const handleModalClose = () => {
  setIsModalOpen(false);
  setmodalImageSrc(null);
};

const defaultTheme = createTheme();

const styles = {
  titletitle: { 
    fontSize:30+(pageWidth/50)
  },
  title: { 
    fontSize:20+(pageWidth/70)
  },
  text: { 
    fontSize:8+(pageWidth/140)
  },
  btntext: { 
    fontSize:6+(pageWidth/170)
  },
  locationBox: { 
    width: pageWidth/100
  },
};

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className='contain'>
        <div className='titleShop'>
        <Box
          sx={{
            pb: 6,
          }}
        >
            <Typography
              sx={{ p: 8 }}
              component="h1"
              variant="h2"
              align="center"
              color="common.white"
              fontFamily="'BodoniFLF', sans-serif"
              letterSpacing={7}
              gutterBottom
              style={styles.titletitle}
            >
              <div className="a">
              SHOP
              </div>
            </Typography>
            <Typography variant="h5" align="centre" color="common.white" paragraph style={styles.text}>
              All of the paintings, prints and sculptures posted on this page are currently available to buy
            </Typography>
            <Typography variant="h6" align="centre" color="common.white" paragraph style={styles.text}>
            If you are interested in any of the following artwork please click the Quote button below the desired piece.
            </Typography>
        </Box>
        </div>
        <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 10000,
          },
          content: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'transparent',
            border: 'none',
            width: '100%',
            maxWidth: '70%',
            height: '100%',
            maxHeight: '70%',
            margin: 'auto',
            overflow: 'hidden'
          },
        }}
      >
        <Grid item key={1} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
                ></Card>
                <Atropos className="atropos">
        <img
          data-atropos-opacity="1;1"
          data-atropos-offset="-5"
          src={modalImageSrc}
          alt="A Load Of Pollocks"
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
        </Atropos>
        <Button onClick={handleModalClose} className="modalbtn" style={{ width: '20%', height: '10%', objectFit: 'contain', background: 'white', overflow:'hidden', left: '40%'}}>Close</Button>
        </Grid>
      </Modal>
      <div className='whitebg'>
      <div className='padbot'>
        <Container className="coolBoxShop" sx={{ py: 8 }} maxWidth="lg">
          <Grid container spacing={4}>
              <Grid item key={1} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <Atropos className="atropos">
                  <img
                      data-atropos-opacity="0.8;1"
                      data-atropos-offset="-5"
                      src={Pollocks}
                      alt="A Load Of Pollocks"
                      className="atroimage"
                    />
                    
                    <img data-atropos-offset="5" className="PaintingForSale" src={ForSale} alt="ForSale" />
                  </Atropos>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    A Load Of Pollocks, Painting
                    </Typography>
                    <Typography>
                    Available for Delivery
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleModalOpen(Pollocks)}>View Fullscreen</Button>
                    <Button href="mailto:karenmcsweeney@karenmcsweeneyart.com?subject=Quote&body=Hi Karen, %0D%0AI am interested in getting a quote for 'A Load Of Pollocks, Painting'" size="small">Quote</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item key={1} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <Atropos className="atropos">
                  <img
                      data-atropos-opacity="0.8;1"
                      data-atropos-offset="-5"
                      src={BoatsRed}
                      alt="Race Day"
                      className="atroimage"
                    />
                    
                    <img data-atropos-offset="5" className="PaintingSold" src={SOLD} alt="Sold" />
                  </Atropos>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    Race Day, Painting
                    </Typography>
                    <Typography>
                    Sold, 2023
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleModalOpen(BoatsRed)}>View Fullscreen</Button>
                    <Button href="mailto:karenmcsweeney@karenmcsweeneyart.com@gmail.com?subject=Quote&body=Hi Karen, %0D%0AI am interested in getting a quote for 'Race Day, Painting'" size="small">Quote</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item key={1} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <Atropos className="atropos">
                  <img
                      data-atropos-opacity="0.8;1"
                      data-atropos-offset="-5"
                      src={BoatsBlue}
                      alt="Yachts"
                      className="atroimage"
                    />
                    
                    <img data-atropos-offset="5" className="PaintingSold" src={SOLD} alt="Sold" />
                  </Atropos>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    Yachts, Painting
                    </Typography>
                    <Typography>
                    Sold, 2023
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleModalOpen(BoatsBlue)}>View Fullscreen</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item key={1} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <Atropos className="atropos">
                  <img
                      data-atropos-opacity="0.8;1"
                      data-atropos-offset="-5"
                      src={BoatsMulti}
                      alt="Under the Gun"
                      className="atroimage"
                    />
                    
                    <img data-atropos-offset="5" className="PaintingSold" src={SOLD} alt="Sold" />
                  </Atropos>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    Under the Gun, Painting
                    </Typography>
                    <Typography>
                    Sold, 2023
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleModalOpen(BoatsMulti)}>View Fullscreen</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item key={1} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <Atropos className="atropos">
                  <img
                      data-atropos-opacity="0.8;1"
                      data-atropos-offset="-5"
                      src={MotherMM}
                      alt="Mother Maiden Matriarch"
                      className="atroimage"
                    />
                    
                    <img data-atropos-offset="5" className="PaintingForSale" src={ForSale} alt="ForSale" />
                  </Atropos>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    Mother Maiden Matriarch, Painting
                    </Typography>
                    <Typography>
                    Available for Delivery
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleModalOpen(MotherMM)}>View Fullscreen</Button>
                    <Button href="mailto:karenmcsweeney@karenmcsweeneyart.com?subject=Quote&body=Hi Karen, %0D%0AI am interested in getting a quote for 'Mother Maiden Matriarch, Painting'" size="small">Quote</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item key={1} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <Atropos className="atropos">
                  <img
                      data-atropos-opacity="0.8;1"
                      data-atropos-offset="-5"
                      src={Sailor}
                      alt="Pirates of Baltimore"
                      className="atroimage"
                    />
                    
                    <img data-atropos-offset="5" className="PaintingForSale" src={ForSale} alt="ForSale" />
                  </Atropos>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    Pirates of Baltimore, Print
                    </Typography>
                    <Typography>
                    Available for Delivery
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleModalOpen(Sailor)}>View Fullscreen</Button>
                    <Button href="mailto:karenmcsweeney@karenmcsweeneyart.com?subject=Quote&body=Hi Karen, %0D%0AI am interested in getting a quote for 'Pirates of Baltimore, Print'" size="small">Quote</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item key={1} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <Atropos className="atropos">
                  <img
                      data-atropos-opacity="0.8;1"
                      data-atropos-offset="-5"
                      src={Seaside}
                      alt="Seaside Prints"
                      className="atroimage"
                    />
                    
                    <img data-atropos-offset="5" className="PaintingForSale" src={ForSale} alt="ForSale" />
                  </Atropos>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    Seaside Prints, Prints
                    </Typography>
                    <Typography>
                    Available for Delivery
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleModalOpen(Seaside)}>View Fullscreen</Button>
                    <Button href="mailto:karenmcsweeney@karenmcsweeneyart.com?subject=Quote&body=Hi Karen, %0D%0AI am interested in getting a quote for 'Seaside Prints, Prints'" size="small">Quote</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item key={1} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <Atropos className="atropos">
                  <img
                      data-atropos-opacity="0.8;1"
                      data-atropos-offset="-5"
                      src={ThreeBoats}
                      alt="Blading Out"
                      className="atroimage"
                    />
                    
                    <img data-atropos-offset="5" className="PaintingForSale" src={ForSale} alt="ForSale" />
                  </Atropos>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    Blading Out, Painting Series
                    </Typography>
                    <Typography>
                    Available for Delivery
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleModalOpen(ThreeBoats)}>View Fullscreen</Button>
                    <Button href="mailto:karenmcsweeney@karenmcsweeneyart.com?subject=Quote&body=Hi Karen, %0D%0AI am interested in getting a quote for 'Blading Out, Painting Series'" size="small">Quote</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item key={1} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <Atropos className="atropos">
                  <img
                      data-atropos-opacity="0.8;1"
                      data-atropos-offset="-5"
                      src={WhiteBoat}
                      alt="1720s"
                      className="atroimage"
                    />
                    
                    <img data-atropos-offset="5" className="PaintingSold" src={SOLD} alt="Sold" />
                  </Atropos>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    1720s, Painting
                    </Typography>
                    <Typography>
                    Sold, 2023
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleModalOpen(WhiteBoat)}>View Fullscreen</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item key={1} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <Atropos className="atropos">
                  <img
                      data-atropos-opacity="0.8;1"
                      data-atropos-offset="-5"
                      src={Thalassophile}
                      alt="Thalassophile"
                      className="atroimage"
                    />
                    
                    <img data-atropos-offset="5" className="PaintingForSale" src={ForSale} alt="ForSale" />
                  </Atropos>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    Thalassophile, Painting
                    </Typography>
                    <Typography>
                    Available for Delivery | YellowDoor Baltimore
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleModalOpen(Thalassophile)}>View Fullscreen</Button>
                    <Button href="mailto:karenmcsweeney@karenmcsweeneyart.com?subject=Quote&body=Hi Karen, %0D%0AI am interested in getting a quote for 'Thalassophile, Painting'" size="small">Quote</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item key={1} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <Atropos className="atropos">
                  <img
                      data-atropos-opacity="0.8;1"
                      data-atropos-offset="-5"
                      src={SailingUntiesTheKnotsInMyHead}
                      alt="Sailing Unties The Knots In My Head"
                      className="atroimage"
                    />
                    
                    <img data-atropos-offset="5" className="PaintingForSale" src={ForSale} alt="ForSale" />
                  </Atropos>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    Sailing Unties The Knots In My Head, Painting 
                    </Typography>
                    <Typography>
                    Available for Delivery | YellowDoor Baltimore
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleModalOpen(SailingUntiesTheKnotsInMyHead)}>View Fullscreen</Button>
                    <Button href="mailto:karenmcsweeney@karenmcsweeneyart.com?subject=Quote&body=Hi Karen, %0D%0AI am interested in getting a quote for 'Sailing Unties The Knots In My Head, Painting'" size="small">Quote</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item key={1} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <Atropos className="atropos">
                  <img
                      data-atropos-opacity="0.8;1"
                      data-atropos-offset="-5"
                      src={PiratesTreasure}
                      alt="Pirates Treasure"
                      className="atroimage"
                    />
                    
                    <img data-atropos-offset="5" className="PaintingForSale" src={ForSale} alt="ForSale" />
                  </Atropos>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    Pirates Treasure, Painting
                    </Typography>
                    <Typography>
                    Available for Delivery | YellowDoor Baltimore
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleModalOpen(PiratesTreasure)}>View Fullscreen</Button>
                    <Button href="mailto:karenmcsweeney@karenmcsweeneyart.com?subject=Quote&body=Hi Karen, %0D%0AI am interested in getting a quote for 'Pirates Treasure, Painting'" size="small">Quote</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item key={1} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <Atropos className="atropos">
                  <img
                      data-atropos-opacity="0.8;1"
                      data-atropos-offset="-5"
                      src={TheJourneyNotTheArrivalMatters}
                      alt="The Journey Not The Arrival Matters"
                      className="atroimage"
                    />
                    
                    <img data-atropos-offset="5" className="PaintingForSale" src={ForSale} alt="ForSale" />
                  </Atropos>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    The Journey Not The Arrival Matters, Painting
                    </Typography>
                    <Typography>
                    Available for Delivery | YellowDoor Baltimore
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleModalOpen(TheJourneyNotTheArrivalMatters)}>View Fullscreen</Button>
                    <Button href="mailto:karenmcsweeney@karenmcsweeneyart.com?subject=Quote&body=Hi Karen, %0D%0AI am interested in getting a quote for 'The Journey Not The Arrival Matters, Painting'" size="small">Quote</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item key={1} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <Atropos className="atropos">
                  <img
                      data-atropos-opacity="0.8;1"
                      data-atropos-offset="-5"
                      src={PiratesTreasure2}
                      alt="Pirates Treasure 2"
                      className="atroimage"
                    />
                    
                    <img data-atropos-offset="5" className="PaintingForSale" src={ForSale} alt="ForSale" />
                  </Atropos>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    Pirates Treasure 2, Painting
                    </Typography>
                    <Typography>
                    Available for Delivery | YellowDoor Baltimore
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleModalOpen(PiratesTreasure2)}>View Fullscreen</Button>
                    <Button href="mailto:karenmcsweeney@karenmcsweeneyart.com?subject=Quote&body=Hi Karen, %0D%0AI am interested in getting a quote for 'Pirates Treasure 2, Painting'" size="small">Quote</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item key={1} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <Atropos className="atropos">
                  <img
                      data-atropos-opacity="0.8;1"
                      data-atropos-offset="-5"
                      src={LostTreasure}
                      alt="Lost Treasure"
                      className="atroimage"
                    />
                    
                    <img data-atropos-offset="5" className="PaintingForSale" src={ForSale} alt="ForSale" />
                  </Atropos>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    Lost Treasure, Painting
                    </Typography>
                    <Typography>
                    Available for Delivery | YellowDoor Baltimore
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleModalOpen(LostTreasure)}>View Fullscreen</Button>
                    <Button href="mailto:karenmcsweeney@karenmcsweeneyart.com?subject=Quote&body=Hi Karen, %0D%0AI am interested in getting a quote for 'Lost Treasure, Painting'" size="small">Quote</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item key={1} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <Atropos className="atropos">
                  <img
                      data-atropos-opacity="0.8;1"
                      data-atropos-offset="-5"
                      src={PeaceOfHeaven}
                      alt="Peace Of Heaven"
                      className="atroimage"
                    />
                    
                    <img data-atropos-offset="5" className="PaintingForSale" src={ForSale} alt="ForSale" />
                  </Atropos>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    Peace Of Heaven, Painting
                    </Typography>
                    <Typography>
                    Available for Delivery | YellowDoor Baltimore
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleModalOpen(PeaceOfHeaven)}>View Fullscreen</Button>
                    <Button href="mailto:karenmcsweeney@karenmcsweeneyart.com?subject=Quote&body=Hi Karen, %0D%0AI am interested in getting a quote for 'Peace Of Heaven, Painting'" size="small">Quote</Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item key={1} xs={12} sm={6} md={4}>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <Atropos className="atropos">
                  <img
                      data-atropos-opacity="0.8;1"
                      data-atropos-offset="-5"
                      src={BlueSeaDay}
                      alt="Blue Sea Day"
                      className="atroimage"
                    />
                    
                    <img data-atropos-offset="5" className="PaintingForSale" src={ForSale} alt="ForSale" />
                  </Atropos>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    Blue Sea Day, Painting
                    </Typography>
                    <Typography>
                    Available for Delivery | YellowDoor Baltimore
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" onClick={() => handleModalOpen(BlueSeaDay)}>View Fullscreen</Button>
                    <Button href="mailto:karenmcsweeney@karenmcsweeneyart.com?subject=Quote&body=Hi Karen, %0D%0AI am interested in getting a quote for 'Blue Sea Day, Painting'" size="small">Quote</Button>
                  </CardActions>
                </Card>
              </Grid>
          </Grid>
        </Container>
        </div>
        </div>
        </div>
      </main>
    </ThemeProvider>
  );
};

export default ShopDesign;