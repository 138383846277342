import "../CSS/About.css";
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BoatsRed from "../images/500x500/boats_red_painting.png";
import ElephantCreate from "../images/500x500/elephant_creation.png";
import TLBBLogo from "../images/500x500/logo.png";
import Elephant from "../images/500x500/elephant_sculpture.png";
import GoogleMap from './GoogleMap';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import Button from '@mui/material/Button';

const defaultTheme = createTheme();

export default function AboutDesign({ pageWidth }) {
  const styles = {
    titletitle: { 
      fontSize:30+(pageWidth/50)
    },
    title: { 
      fontSize:20+(pageWidth/70)
    },
    text: { 
      fontSize:8+(pageWidth/140)
    },
    btntext: { 
      fontSize:6+(pageWidth/170)
    },
    locationBox: { 
      width: pageWidth/100
    },
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <main>
      <div className='contain'>
      <div className='titleAbout padbotsm'>
            <Typography
              sx={{ p: 8 }}
              component="h1"
              variant="h2"
              align="center"
              color="common.white"
              fontFamily="'BodoniFLF', sans-serif"
              letterSpacing={7}
              gutterBottom
              style={styles.titletitle}
            >
              <div className="a">
              KAREN MCSWEENEY
              </div>
            </Typography>
        </div>
        <div className='whitebg extrapad'>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography
              component="h1"
              variant="h2"
              align="left"
              color="text.primary"
              fontFamily="'BodoniFLF', sans-serif"
              style={styles.title}
              gutterBottom
            >
              ABOUT THE ARTIST
            </Typography>
            <Typography className="textleft" variant="h6" align="left" color="common.primary" paragraph style={styles.text}>
            Karen McSweeney splits her time between Dublin and West Cork. In both locations, she lives near the coast, which absolutely influences her work. Oil or Acrylic is her favourite medium, with a free, bright style. She also works with watercolour and other mixed mediums too.
            </Typography>
            <Typography className="textleft" variant="h6" align="left" color="common.primary" paragraph style={styles.text}>
            The theme of Karen McSweeney's art is nautical and blue. Her Art has been described as having "A feeling of Movement".
            </Typography>
            <Typography className="textleft" variant="h6" align="left" color="common.primary" paragraph style={styles.text}>
            If you are interested in reaching out to Karen McSweeney for any reason, feel free to click the Enquiry button below.
            </Typography>
            <a href="mailto:karenmcsweeney@karenmcsweeneyart.com?subject=Enquiry&body=Hi Karen, %0D%0AI would like to enquire about ..."><Button variant="outlined" style={styles.btntext}>Enquire</Button></a>
            </Grid>
            <Grid item xs={4} className="imggridr">
              <div className="imggridr">
                    <img
                        src={BoatsRed}
                        alt="Race Day"
                        className="atroimage2"
                      />
              </div>
              </Grid>
            </Grid>
        </div>
        <div className='offwhitebg extrapad'>
        <Grid container spacing={2}>
        <Grid item xs={4} className="imggridr">
              <div className="imggridl">
                    <img
                        src={ElephantCreate}
                        alt="Elephant Creation"
                        className="atroimage2"
                      />
              </div>
              </Grid>
          <Grid item xs={8}>
            <Typography
              component="h1"
              variant="h2"
              align="left"
              color="text.primary"
              fontFamily="'BodoniFLF', sans-serif"
              gutterBottom
              style={styles.title}
            >
              CHARITY WORK
            </Typography>
            <Typography className="textleft" variant="h6" align="left" color="common.primary" paragraph style={styles.text}>
            Karen has completed work for many charities over the years, among them Jack & Jill, Make a Wish, Zest4Kids, RNLI, Childcry and Samaritans. 
            Some of the most notable charity work that Karen has worked on is the various sculptures placed around Dublin painted by a select group of high profile artists. 
            Some of these works include the Jack and Jill <a href="http://thebigegghunt.ie/"> Egg Hunt </a> which was placed in Arnotts, <a href="http://pigsonparade.ie//"> Pigs on Parade </a> which could be found in Easons and <a href="http://www.haresonthemarch.ie///"> Hares on the March </a> which could be viewed in the Radison Hotel.
            </Typography>
            <Typography className="textleft" variant="h6" align="left" color="common.primary" paragraph style={styles.text}>
            The most recent and arguably the most notable of the lot is the Samaritans <a href="https://www.elephantintheroom.ie/about-elephant-in-the-room"> Elephant in the Room </a> which aims to raise mental health awareness by encouraging people to not be ashamed to address the 'Elephant in the room'. Karen's artwork was available to view at St Stephens Green Shopping centre Dublin in a prime location. Karen worked on various pieces of artwork for the Royal National Lifeboat Institution and the Make a Wish childrens charity to be sold in an art auction to raise money for the cause.
            </Typography>
            <Typography className="textleft" variant="h6" align="left" color="common.primary" paragraph style={styles.text}>
            Finally, Karen has also written and illustrated multiple childrens books for various charities and causes. These books include titles such as 'The Happiest Farm In The World' and 'Benson The Bear'; One of which '<a href="https://www.amazon.co.uk/Grumpy-Cow-Story-Silent-Night/dp/1907107886">'The Grumpy Cow'</a>' has gone on to be a regular and popular Christmas play in various schools in Dublin.
            </Typography>
            </Grid>
            </Grid>
        </div>
        <div className='whitebg extrapad'>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography
              component="h1"
              variant="h2"
              align="left"
              color="text.primary"
              fontFamily="'BodoniFLF', sans-serif"
              gutterBottom
              style={styles.title}
            >
              THE LITTLE BLUE BOAT
            </Typography>
            <Typography className="textleft" variant="h6" align="left" color="common.primary" paragraph style={styles.text}>
            Karen McSweeney's Art is included and sold in many galleries throughout West Cork. She is a member of the Uileann West Cork Art Centre and is registered with the Design and Craft council of Ireland.
            </Typography>
            <Typography className="textleft" variant="h6" align="left" color="common.primary" paragraph style={styles.text}>
            The Little Blue Boat is a side of Karen McSweeney art aimed at selling smaller pieces, and she sees it as 'a dealer in whimsy'!
            </Typography>
            </Grid>
            <Grid item xs={4} className="imggridr">
              <div className="imggridr">
                    <img
                        src={TLBBLogo}
                        alt="The little blue boat logo"
                        className="atroimage2"
                      />
              </div>
              </Grid>
            </Grid>
        </div>
        <div className='offwhitebg extrapad helpflex'>
        <Grid container spacing={2}>
        <Grid item xs={4} className="imggridr">
              <div className="imggridl">
                    <img
                        src={Elephant}
                        alt="Elephant Sculpture"
                        className="atroimage2"
                      />
              </div>
              </Grid>
          <Grid item xs={8}>
            <Typography
              component="h1"
              variant="h2"
              align="left"
              color="text.primary"
              fontFamily="'BodoniFLF', sans-serif"
              gutterBottom
              style={styles.title}
            >
              EVENT WORK
            </Typography>
            <Typography className="textleft" variant="h6" align="left" color="common.primary" paragraph style={styles.text}>
            Karen has also worked on multiple events for well known clients. Some of these clients reviews are displayed below which clearly show the amount of time and attention is given to each and every event.
            </Typography>
            <Typography className="textleft" variant="h6" align="left" color="common.primary" paragraph style={styles.text}>
            If you are interested in booking Karen for an art event please reach out by clicking the button below.
            </Typography>
            <a href="mailto:karenmcsweeney@karenmcsweeneyart.com?subject=Event Enquiry&body=Hi Karen, %0D%0AI am interested in booking you for an event. Could you reply to my email with a quote for ''"><Button variant="outlined" style={styles.btntext}>Event Enquire</Button></a>
            </Grid>
            </Grid>
        </div>
        <div className='whitebg extrapad'>
            <Typography
              component="h1"
              variant="h2"
              align="centre"
              color="text.primary"
              fontFamily="'BodoniFLF', sans-serif"
              gutterBottom
              position={"relative"}
              style={styles.title}
            >
              REVIEWS
            </Typography>
            <Swiper
        grabCursor={true}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
        slidesPerView={'1'}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Navigation, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
        <Typography className="textleft reviewtext" variant="h4" align="centre" color="common.primary" paragraph style={styles.text}>
            "Pablo Picasso once said 'Art washes away from the soul the dust of everyday life' and for sure this is what the work of Karen McSweeney does. She sees the ordinary (and that might be a piece of washed up glass or a yacht at full tilt) and she presents it in a way that lights up our souls. She though a mixture of mediums opens our eyes to see magnificence in simplicity and divine beauty in the ordinary. She truly operates in the space of the impressionist Edgar Degas who reminded us that 'Art is not what you see, but what you make others see.'"
        </Typography>
        <Typography className="textleft " variant="h6" align="centre" color="common.primary" paragraph style={styles.text}>
            Robert Carley, Global Wellness Advocate Paypal
        </Typography>
        </SwiperSlide>
        <SwiperSlide>
        <Typography className="textleft reviewtext" variant="h4" align="centre" color="common.primary" paragraph style={styles.text}>
            "Karen's skill in responding to brieds is truly commendable. She has a keen understanding of the clients vision and requirements, which she masterfully incorporates into her artwork. The results are nothing shor of excellence, as her creations consistenly exceed expectations and breathe life into the original concepts."
        </Typography>
        <Typography className="textleft " variant="h6" align="centre" color="common.primary" paragraph style={styles.text}>
            Gwen Kenny, Design Director Divine Design, Vice President Interiors Association
        </Typography>
        </SwiperSlide>
        <SwiperSlide>
        <Typography className="textleft reviewtext" variant="h4" align="centre" color="common.primary" paragraph style={styles.text}>
            "Members and Friends Exhibition: Uillinn West Cork Arts Centre, Skibbereen, Co Cork. This exhibition, now in its 35th year, runs until July 20th and showcases artists from across west Cork at all stages in their career. Works include paintings, sculpture, photography, print and mixed media by Deirdre Andreson, Johanna Ashby, Karen McSweeney and Sarah Ruttle."
        </Typography>
        <Typography className="textleft " variant="h6" align="centre" color="common.primary" paragraph style={styles.text}>
            Elizabeth Birdthistle, Irish Times
        </Typography>
        </SwiperSlide>
      </Swiper>
        </div>
        <div className='offwhitebg extrapad'>
            <Typography
              component="h1"
              variant="h2"
              align="Centre"
              color="text.primary"
              fontFamily="'BodoniFLF', sans-serif"
              gutterBottom
              style={styles.title}
            >
              LOCATION
            </Typography>
            <GoogleMap pageWidth={pageWidth}/>
        </div>
        </div>
      </main>
    </ThemeProvider>
  );
}