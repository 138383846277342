import "./CSS/Style.css";
import Shop from './Pages/Shop';
import About from './Pages/About';
import Gallery from './Pages/Gallery';
import NoPage from './Pages/NoPage';
import TheLittleBlueBoat from './Pages/TheLittleBlueBoat';
import { BrowserRouter, Route, Routes } from "react-router-dom";

export default function App ({ pageWidth }) {
  return (
    <div className="anim_gradient">
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route index element={<About pageWidth={pageWidth}/>}/>
            <Route path="/about" element={<About pageWidth={pageWidth}/>}/>
            <Route path="/shop" element={<Shop pageWidth={pageWidth}/>}/>
            <Route path="/gallery" element={<Gallery pageWidth={pageWidth}/>}/>
            <Route path="/thelittleblueboat" element={<TheLittleBlueBoat pageWidth={pageWidth}/>}/>
            <Route path="*" element={<NoPage pageWidth={pageWidth}/>}/>
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}
