import '../CSS/Gallery.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { Autoplay, Navigation, EffectCoverflow, Pagination } from 'swiper/modules';
import Pollocks from "../images/500x500/a_load_of_pollocks.png";
import Blue from "../images/500x500/blue_painting.png";
import BoatsRed from "../images/500x500/boats_red_painting.png";
import BoatsBlue from "../images/500x500/BoatsBlue.png";
import BoatsMulti from "../images/500x500/colourful_boats_painting.png";
import ElephantCreate from "../images/500x500/elephant_creation.png";
import Elephant from "../images/500x500/elephant_sculpture.png";
import Hare from "../images/500x500/hare_sculpture.png";
import Poem from "../images/500x500/james_joyce_quote.png";
import MotherMM from "../images/500x500/mother_maiden_matriarch.png";
import Pauline from "../images/500x500/pauline_bewick.png";
import Pig from "../images/500x500/pig_sculpture.png";
import Sailor from "../images/500x500/sailor_painting.png";
import Sea from "../images/500x500/sea_photo.png";
import Seaside from "../images/500x500/seaside_paintings.png";
import ThreeBoats from "../images/500x500/three_boat_paintings.png";
import Uilinn from "../images/500x500/uilinn_newspaper.png";
import WhiteBoat from "../images/500x500/white_boats_painting.png";
import Thalassophile from "../images/500x500/Thalassophile.png";
import SailingUntiesTheKnotsInMyHead from "../images/500x500/SailingUntiesTheKnotsInMyHead.png";
import PiratesTreasure from "../images/500x500/PiratesTreasure.png";
import TheJourneyNotTheArrivalMatters from "../images/500x500/TheJourneyNotTheArrivalMatters.png";
import PiratesTreasure2 from "../images/500x500/PiratesTreasure2.png";
import LostTreasure from "../images/500x500/LostTreasure.png";
import PeaceOfHeaven from "../images/500x500/PeaceOfHeaven.png";
import BlueSeaDay from "../images/500x500/BlueSeaDay.png";
import WimHof from "../images/500x500/Wim_Hof.png";
import ChristianHorner from "../images/500x500/Christian_Horner.png";
import ChristianHorner2 from "../images/500x500/Christian_Horner_2.png";
import HarryRedknapp from "../images/500x500/Harry_Redknapp.png";
import JohnGrayCharlieEngle from "../images/500x500/John_Gray_Charlie_Engle.png";
import CharlieEngle from "../images/500x500/Charlie_Engle.png";
import NiallBreslin from "../images/500x500/Niall_Breslin.png";
import WimHofSigning from "../images/500x500/Wim_Hof_Signing.gif";
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';

const defaultTheme = createTheme();

export default function GalleryDesign({ pageWidth }) {
  const styles = {
    titletitle: { 
      fontSize:30+(pageWidth/50)
    },
    title: { 
      fontSize:20+(pageWidth/70)
    },
    text: { 
      fontSize:8+(pageWidth/140)
    },
    btntext: { 
      fontSize:6+(pageWidth/170)
    },
    locationBox: { 
      width: pageWidth/100
    },
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <div className='contain'>
        <div className='titleGallery'>
        <div className='padbot'>
          <Container className="" maxWidth="md">
            <Typography
              sx={{ p: 8 }}
              component="h1"
              variant="h2"
              align="center"
              color="common.white"
              fontFamily="'BodoniFLF', sans-serif"
              letterSpacing={7}
              gutterBottom
              style={styles.titletitle}
            >
              <div className="a">
              GALLERY
              </div>
            </Typography>
            <Typography variant="h5" align="centre" color="common.white" paragraph style={styles.text}>
              This Gallery contains a collection of images associated with Karen McSweeney.
            </Typography>
          </Container>
        </div>
        </div>
        <div className='whitebg'>
        <div className='padbot'>
    <Container className="coolBoxGallery" maxWidth="lg">
    <>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 10,
          modifier: 1,
          slideShadows: true,
        }}
        zoom={true}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Navigation, EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="swiper-zoom-container">
            <img src={Pollocks} alt="A Load Of Pollocks"/>
          </div>
        </SwiperSlide>
        <SwiperSlide>
        <img src={Blue} alt="Gallery Painting"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={BoatsRed} alt="Race Day"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={BoatsBlue} alt="Yachts"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={BoatsMulti} alt="Under the Gun"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={ElephantCreate} alt="Creating the Elephant"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={Elephant} alt="Elephant on Display"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={Hare} alt="Hare on Display"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={Poem} alt="James Joyce Poem"/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={MotherMM} alt="Mother Maiden Matriarch"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={Pauline} alt="Pauline Bewick"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={Pig} alt="Pig on Display"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={Sailor} alt="Pirates of Baltimore"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={Sea} alt="Sea Day"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={Seaside} alt="Seaside Prints"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={ThreeBoats} alt="Blading Out"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={Uilinn} alt="Newspaper Article"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={WhiteBoat} alt="1720s"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={Thalassophile} alt="Thalassophile"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={SailingUntiesTheKnotsInMyHead} alt="Sailing Unties The Knots In My Head"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={PiratesTreasure} alt="Pirates Treasure"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={TheJourneyNotTheArrivalMatters} alt="The Journey Not The Arrival Matters"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={PiratesTreasure2} alt="Pirates Treasure 2"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={LostTreasure} alt="Lost Treasure"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={PeaceOfHeaven} alt="Peace Of Heaven"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={BlueSeaDay} alt="Blue Sea Day"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={WimHof} alt="Wim Hof"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={ChristianHorner} alt="Christian Horner"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={ChristianHorner2} alt="Christian Horner"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={HarryRedknapp} alt="Harry Redknapp"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={JohnGrayCharlieEngle} alt="John Gray and Charlie Engle"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={CharlieEngle} alt="Charlie Engle"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={NiallBreslin} alt="Niall Breslin"/>
        </SwiperSlide>
        <SwiperSlide>
        <img src={WimHofSigning} alt="Wim Hof Signing"/>
        </SwiperSlide>
      </Swiper>
    </>
    </Container>
    </div>
    </div>
    </div>
    </ThemeProvider>
  );
}
